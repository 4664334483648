import React from "react"
import desarrolloCss from "../../css/desarrollo.module.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AlexaIMG from "../../components/images/skills/skills"
import MenuDesarrollo from "../../components/menuDesarrollo/menuDesarrollo"
import LogoBlanco from "../../images/logo-blanco.svg"
import { Link } from "gatsby"

const Alexa = () => (
  <>
    <div className={desarrolloCss.background}>
      <div className={desarrolloCss.gradient}></div>
    </div>
    <AlexaIMG></AlexaIMG>
    <Link to="/" className={desarrolloCss.logoBlanco}>
    <img src={LogoBlanco} alt="Logotipo de engrane version blanca"/>
    </Link>
    <MenuDesarrollo></MenuDesarrollo>
    <div className={desarrolloCss.container}>
      <Layout siteTitle="Desarrollo">
        <SEO title="Habilidades de Alexa" />
        <div className={desarrolloCss.contenido}>
          <h1 className={desarrolloCss.titulo}>
            talk to <br />
            alexa
          </h1>
          <p className={desarrolloCss.text}>
            ¿Estás familiarizado con ALEXA? ¿Quieres controlar dispositivos
            inteligentes con ella? Te imaginas que al salir de su despacho diga:
            “Alexa adiós”y se haga toda su rutina de seguridad como apagar luces
            activar cámaras de seguridad y encender alarmas, eso y mucho más
            potencial se puede sacar a los comandos personalizados de Alexa.
          </p>
        </div>
      </Layout>
    </div>
    <Link className={desarrolloCss.productos}>
      PRODUCTOS <span>&nbsp;&nbsp;&nbsp;→</span>
    </Link>
  </>
)

export default Alexa
